import React, { useEffect, useState  } from 'react';
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import Map from "components/Map";
import Suggest from 'components/form/Suggest';


const Rider = ({ location }) => {
  const [marker, setMarker] = useState();

  const onMapMarker = async e => {
    setMarker(e)
    console.log('onMapMarker',e);
  }

  return (
    <Layout className="layout-fixed">
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      <section>
        <div  className="container">
          <h4>مكان النزول</h4>
        </div>
      </section>
      <section className="body-fixed">
        <div  className="container container-map">
          <Suggest />
          <Map onMarker={onMapMarker} marker={marker} />
        </div>
      </section>
      <section>
        <div  className="container">
          <Link to="../3" role="button" className="secondary wide">المتابعة 2/6</Link>
        </div>
      </section>
    </Layout>
  );
}

export default Rider
